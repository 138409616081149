<template>
  <div class="kategori_pekerjaan">
    <h1 class="mb-5">Data Kategori Pekerjaan</h1>
    <div class="row">
      <!-- Card -->
      <div class="col-md-12">
        <div class="card">
          <h5
            class="
              card-header
              d-flex
              justify-content-between
              align-items-center
            "
          >
            Daftar Kategori Pekerjaan
            <button
              @click="showFormModal = true"
              class="btn btn-sm btn-primary"
            >
              <i class="bi bi-plus-circle"></i> Tambah Kategori Pekerjaan
            </button>
          </h5>
          <div class="card-body table-responsive">
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Cari"
                aria-label="Cari"
                aria-describedby="button-addon2"
                v-model="search"
                @keypress.enter="searchData"
              />
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="searchData"
              >
                Cari
              </button>
            </div>
            <transition name="fade">
              <div class="text-center" v-if="isLoading">
                <div class="spinner-grow" role="status"></div>
              </div>
              <Table
                v-else
                :headers="headers"
                :items="kategori_pekerjaan"
                @edit="editItem"
                @delete="confirmDelete"
              />
            </transition>
            <Pagination
              v-if="kategori_pekerjaan.length"
              :currentPage="currentPage"
              :totalData="totalData"
              :totalPage="totalPage"
              @nextPage="nextPage"
              @previousPage="previousPage"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Modal konfirmasi hapus -->
    <delete-modal
      :showModal="showDeleteModal"
      :id="selectedKategoriPekerjaanId"
      @closeModal="closeDeleteModal"
      @destroy="destroyKategoriPekerjaan"
    />

    <!-- Modal tambah/edit -->
    <form-modal
      :showModal="showFormModal"
      :editedIndex="editedIndex"
      :editedId="editedId"
      :editedItem="editedItem"
      @closeModal="closeForm"
      @save="saveForm"
    />
  </div>
</template>

<script>
import { kategoriPekerjaanService } from "@/services";
import DeleteModal from "./components/DeleteModal.vue";
import FormModal from "./components/FormModal.vue";
import Table from "./components/Table.vue";
import Pagination from "./components/Pagination.vue";

export default {
  components: {
    Table,
    FormModal,
    DeleteModal,
    Pagination,
  },
  data() {
    return {
      // Search query
      search: "",
      limit: 10,
      sort: "updatedAt",
      order: "desc",

      // Pagination
      totalData: 0,
      currentPage: 1,

      // Loading State
      isLoading: false,

      // Data Array
      headers: ["Nama", "Tanggal Dibuat", "Tanggal Diubah", "Tindakan"],
      kategori_pekerjaan: [],

      // Modal state
      showDeleteModal: false,
      showFormModal: false,
      selectedKategoriPekerjaanId: 0,

      // Default form state
      editedIndex: -1,
      editedId: 0,
      editedItem: {
        nama: "",
      },
      defaultItem: {
        nama: "",
      },
    };
  },
  computed: {
    totalPage() {
      return Math.ceil(this.totalData / this.limit);
    },
  },
  methods: {
    // Search & Load
    searchData() {
      this.currentPage = 1;
      this.isLoading = true;
      this.loadData(
        this.limit,
        this.search,
        this.currentPage,
        this.sort,
        this.order
      );
    },
    loadData(limit, search, page, sort, order) {
      kategoriPekerjaanService
        .getAll(limit, search, page, sort, order)
        .then((data) => {
          this.kategori_pekerjaan = data.rows;
          this.totalData = data.count;
          this.currentPage = page;
        });
      this.isLoading = false;
    },

    // Pagination
    previousPage() {
      this.loadData(
        this.limit,
        this.search,
        this.currentPage - 1,
        this.sort,
        this.order
      );
    },
    nextPage() {
      this.loadData(
        this.limit,
        this.search,
        this.currentPage + 1,
        this.sort,
        this.order
      );
    },

    // Modal
    editItem(item) {
      this.editedIndex = this.kategori_pekerjaan.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showFormModal = true;
    },
    async saveForm() {
      const { nama } = this.editedItem;
      if (this.editedIndex > -1) {
        // Update data
        try {
          await kategoriPekerjaanService.update({ nama }, this.editedItem.id);
          this.closeForm();
          await this.searchData();
        } catch (error) {
          this.$toast.error(
            (error.response && error.response.data.errors[0].message) ||
              error.message
          );
        }
      } else {
        // Insert new data
        try {
          await kategoriPekerjaanService.create(this.editedItem);
          this.closeForm();
          await this.searchData();
        } catch (error) {
          this.$toast.error(
            (error.response && error.response.data.errors[0].message) ||
              error.message
          );
        }
      }
    },
    closeForm() {
      this.showFormModal = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
    },
    confirmDelete(id) {
      this.showDeleteModal = true;
      this.selectedKategoriPekerjaanId = id;
    },
    destroyKategoriPekerjaan(id) {
      kategoriPekerjaanService
        .destroy(id)
        .then(() => {
          this.$toast.success(`Kategori Pekerjaan berhasil dihapus`);
          this.showDeleteModal = false;
          this.selectedKategoriPekerjaanId = 0;
          this.searchData();
        })
        .catch((error) => {
          this.$toast.error(
            (error.response && error.response.data.message) || error.message
          );
        });
    },
  },
  mounted() {
    this.isLoading = true;
    this.loadData(
      this.limit,
      this.search,
      this.currentPage,
      this.sort,
      this.order
    );
  },
};
</script>

<style scoped>
.kategori_pekerjaan {
  padding: 3rem 1.5rem;
}
</style>
