<template>
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col" v-for="header in headers" :key="header">
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="!items.length">
        <td colspan="4" class="text-center">
          <span>Tidak ada data ditemukan.</span>
        </td>
      </tr>
      <TableItem
        v-for="item in items"
        :key="item.id"
        :data="item"
        @edit="edit"
        @delete="confirmDelete"
      />
    </tbody>
  </table>
</template>

<script>
import TableItem from "./TableItem.vue";
export default {
  props: {
    headers: {
      type: Array,
    },
    items: {
      type: Array,
    },
  },
  components: {
    TableItem,
  },
  methods: {
    edit(data) {
      this.$emit("edit", data);
    },
    confirmDelete(id) {
      this.$emit("delete", id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
