<template>
  <tr>
    <td width="30%">{{ data.nama }}</td>
    <td>{{ localDate(data.updatedAt) }}</td>
    <td>{{ localDate(data.createdAt) }}</td>
    <td width="20%">
      <div class="btn-group" role="group" aria-label="Action menu">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          @click="edit(data)"
        >
          <i class="bi bi-pencil"></i> Edit
        </button>
        <button
          type="button"
          class="btn btn-danger btn-sm"
          @click="confirmDelete(data.id)"
        >
          <i class="bi bi-trash"></i> Hapus
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: "TableItem",
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    localDate(date) {
      return date.substring(0, 10);
    },
    edit(data) {
      this.$emit("edit", data);
    },
    confirmDelete(id) {
      this.$emit("delete", id);
    },
  },
};
</script>

<style scoped></style>
